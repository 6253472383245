import { format, formatDistance, differenceInDays } from "date-fns"
import React, { useEffect, useState, useRef, Fragment } from 'react'
import { Link } from "gatsby"
import { buildImageObj } from "../lib/helpers"
import { imageUrlFor } from "../lib/image-url"
import BlockContent from "./block-content"
import Container from "./container"
import RoleList from "./role-list"
import { Image, Video, Transformation, Placeholder } from 'cloudinary-react'
import ReactDOM from 'react-dom'

import * as styles from "./project.module.css"
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/lazy'
import "swiper/css/free-mode"

import SwiperCore, { Navigation, Keyboard, Lazy, A11y, FreeMode } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import OffCross from '../images/croix.svg'

/* 
EMPTY LAST SLIDE

          < SwiperSlide >
            <div id="lastChild"></div>
          </SwiperSlide>

*/





function Project(props) {


  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }


  const swiperRef = React.useRef(null);
  SwiperCore.use([Navigation, Keyboard, Lazy])

  // Constantes

  //const [swiper, setSwiper] = React.useState(null);
  const { _rawBody, title, mainImage, cloudinaryList, colors, lieu, surface } = props;
  const btnDesc = `${title} 〔${lieu}〕`;
  const [hasMounted, setHasMounted] = useState(false);

  const numberSlider = props.cloudinaryList;
  const countSlides = Object.keys(numberSlider).length;


  useEffect(() => {
    setHasMounted(true);

    // Colors
    if (colors) {
      document.querySelector('body').style.backgroundColor = colors.value;
    }

    // Overflow

    if (!iOS()) {
      const container = document.querySelector(".container")
      container.style.overflow = "hidden"
    }


    // A Link
    const links = document.querySelectorAll("a")
    links.forEach((link) => {
      if (link.target) {
        return;
      } else if (link.host !== window.location.host) {
        link.target = "_blank";
        link.rel = "noopener";
      } else {
        link.target = "_self";
      }
    });
  }, []);

  if (!hasMounted) {
    return null;
  }



  // Let's create a Modal component that is an abstraction around
  // the portal API.
  class Modal extends React.Component {
    constructor(props) {
      super(props);
      // Create a div that we'll render the modal into. Because each
      // Modal component has its own element, we can render multiple
      // modal components into the modal container.
      this.el = document.createElement('div');
    }
    componentDidMount() {
      // Append the element into the DOM on mount. We'll render
      // into the modal container element (see the HTML tab).
      const container = document.getElementById('container-root');
      const sideMenu = document.getElementById('side-menu');
      sideMenu.appendChild(this.el);
    }

    componentWillUnmount() {
      // Remove the element from the DOM when we unmount
      // sideMenu.removeChild(this.el);

      const container = document.getElementById('container-root');
      const sideMenu = document.getElementById('side-menu');
      if (sideMenu) {
        sideMenu.removeChild(this.el);
      }

    }

    render() {
      // Use a portal to render the children into the element
      return ReactDOM.createPortal(
        // Any valid React child: JSX, strings, arrays, etc.
        this.props.children,
        // A DOM element
        this.el,
      );
    }
  }

  // Toggle offcanvas

  class Toggle extends React.Component {
    constructor(props) {
      super(props);
      this.state = { isToggleOn: true };
      this.handleClick = this.handleClick.bind(this);
      this.el = document.createElement('div');
      this.container = document.getElementById('container-root');
    }

    handleClick() {
      this.setState(state => ({
        isToggleOn: !state.isToggleOn
      }));

      if (this.state.isToggleOn == true) {
        // console.log('toggleon');
        document.getElementById("container-root").classList.add("isOff");
        document.querySelector('header').classList.add("NavIsOff");
        document.querySelector('footer').classList.add("NavIsOff");
        document.getElementById('title-btn').classList.add("NavIsOff");
        if (document.querySelector('.swiper-wrapper')) {
          document.querySelector('.swiper-wrapper').classList.add("SwiperIsOff");
        }
      } else {
        document.getElementById("container-root").classList.remove("isOff");
        document.querySelector('header').classList.remove("NavIsOff");
        document.querySelector('footer').classList.remove("NavIsOff");
        document.getElementById('title-btn').classList.remove("NavIsOff");
        if (document.querySelector('.swiper-wrapper')) {
          document.querySelector('.swiper-wrapper').classList.remove("SwiperIsOff");
        }
      }
    }

    updateDimensions = () => {
      console.log('window size updated');
    };

    componentDidMount() {
      const container = document.getElementById('container-root');
      const sideMenu = document.getElementById('side-menu');
      sideMenu.appendChild(this.el);

      window.addEventListener('resize', this.updateDimensions);

    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }



    render() {
      return (
        <Fragment>
          <Modal>
            <div className={styles.title__btn} id="title-btn">
              <button onClick={this.handleClick} className={this.state.isToggleOn ? 'btn' : 'btn__close'}>
                {btnDesc}
              </button>
            </div>
            <div className={this.state.isToggleOn ? 'sidebar close' : 'sidebar open'} >
              <div className="sidebar__title">
                <h2>{title}</h2>
              </div>

              <br />
              <br />

              {_rawBody && <BlockContent blocks={_rawBody || []} />}
              <br />
              <p id="surface">
                <span>Surface: </span>
                <span>{surface}</span>
              </p>
              <p id="lieu">
                <span>Lieu: </span>
                <span>{lieu}</span>
              </p>
              <div className={styles.exit__btn} id="croix">
                <button onClick={this.handleClick}>
                  <img src={OffCross} alt="Croix" />
                </button>
              </div>
            </div>
          </Modal>
        </Fragment>
      );
    }
  }

  //



  if (iOS() && hasMounted) {

    console.log('ios device 666')
    return (
      <article>
        <Toggle />
        <Container>

          <div id="previousButton" onClick={() => swiperRef.current.swiper.slidePrev()} />
          <div id="nextButton" onClick={() => swiperRef.current.swiper.slideNext()} />

          {props.cloudinaryList.length > 1 ?
            <div id="iosNoCarousel">
              {props.cloudinaryList &&
                props.cloudinaryList.map(carItem =>
                  <div key={carItem._key}>
                    {carItem.resource_type == 'image' ?
                      <Image cloudName="dxa7id4bl" publicId={carItem.public_id} alt={`Image: ${carItem.public_id}`}>
                        <Transformation crop="scale" width="1200" fetchFormat="auto" />
                      </Image>
                      :
                      <Video cloudName="dxa7id4bl" publicId={carItem.public_id} controls={false} autoPlay loop muted
                        fallbackContent="Your browser does not support HTML5 video tags.">
                      </Video>
                    }
                  </div>
                )}
            </div>
            : <h2>Nothing here</h2>}
        </Container>
      </article >
    )
  } else if (hasMounted) {
    return (
      <article>
        <Toggle />
        <Container>

          <div id="previousButton" onClick={() => swiperRef.current.swiper.slidePrev()} />
          <div id="nextButton" onClick={() => swiperRef.current.swiper.slideNext()} />

          {props.cloudinaryList.length > 1 ?
            <Swiper
              ref={swiperRef}
              modules={[Navigation, Keyboard, Lazy, FreeMode]}
              className="mySwiper"
              freeMode={false}
              observer={true}
              observeParents={true}
              observeSlideChildren={true}
              updateOnWindowResize
              spaceBetween={8}
              speed={300}
              slidesPerView={"auto"}
              initialSlide={0}
              loop={true}
              loopedSlides={countSlides}
              keyboard={true}
              preloadImages={true}
              lazy={{
                loadPrevNext: true,
                loadPrevNextAmount: 4
              }}
              onSwiper={swiper => {
                //  console.log(swiper)
                setTimeout(() => {
                  swiper.update()
                }, 3000);
              }}
              onLazyImageReady={
                swiper => {
                  swiper.update()
                }
              }
              // onSlideChange={() => console.log('slide change')}
              breakpoints={{
                320: {
                  freeMode: {
                    enabled: true,
                  },
                  speed: 100,
                  minimumVelocity: 5,
                  navigation: false,
                  grabCursor: true,
                  allowTouchMove: true,
                },
                768: {
                  freeMode: {
                    enabled: true,
                  },
                  speed: 100,
                  minimumVelocity: 5,
                  navigation: false,
                  grabCursor: true,
                  allowTouchMove: true,
                },
                1024: {
                  freeMode: {
                    enabled: false,
                  },
                  speed: 300,
                  navigation: true,
                  allowTouchMove: false,
                  allowSlidePrev: true,
                  allowSlideNext: true,
                },
              }}
            >

              {props.cloudinaryList &&
                props.cloudinaryList.map(carItem =>
                  <SwiperSlide key={carItem._key}>
                    {carItem.resource_type == 'image' ?
                      <Image className="swiper-lazy" cloudName="dxa7id4bl" publicId={carItem.public_id} loading="lazy" alt={`Image: ${carItem.public_id}`}>
                        <Transformation crop="scale" width="1200" fetchFormat="auto" />
                        <Placeholder type="blur" />
                      </Image>
                      :
                      <Video cloudName="dxa7id4bl" publicId={carItem.public_id} controls={false} autoPlay loop muted
                        fallbackContent="Your browser does not support HTML5 video tags.">
                      </Video>
                    }
                  </SwiperSlide>
                )}
            </Swiper>
            : <h2>Nothing here</h2>}
        </Container>
      </article >
    )
  }
}

export default Project;
